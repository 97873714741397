import { Plan } from '@helpers/firebase_helper';
import { getStatusDescription } from 'pages/PlanList/planHelper';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
} from 'reactstrap';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import withRouter from '../../Components/Common/withRouter';
import { getFirebaseBackend } from '../../firebase';

const AdminUserActivity = () => {
  document.title = 'Admin - User Activity';

  const [editingAllowedDocuments, setEditingAllowedDocuments] = useState(false);
  const [newAllowedDocuments, setNewAllowedDocuments] = useState('0');
  const [isAdmin, setIsAdmin] = useState(false);

  const [usersActivity, setUsersActivity] = useState<
    {
      user: {
        uid: string;
        email: string;
        displayName: string;
        provider: any;
        metadata: any;
        numberOfAllowedDocuments: string;
        claims: {
          [key: string]: any;
        } | null;
      };
      logins: {
        userId: string;
        email: string;
        timestamp: any;
        metadata: any;
      }[];
      plans: Plan[];
    }[]
  >([]);
  const [selectedUserActivity, setSelectedUserActivity] = useState<{
    user: {
      uid: string;
      email: string;
      displayName: string;
      provider: any;
      metadata: any;
      numberOfAllowedDocuments: string;
      claims: {
        [key: string]: any;
      } | null;
    };
    logins: {
      userId: string;
      email: string;
      timestamp: any;
      metadata: any;
    }[];
    plans: Plan[];
  } | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleEditAllowedDocuments = (userActivity: any) => {
    setNewAllowedDocuments(userActivity.user.numberOfAllowedDocuments);
    setEditingAllowedDocuments(true);
  };

  const handleSaveAllowedDocuments = async (userActivity: any) => {
    try {
      const firebaseHelper = getFirebaseBackend();
      // Update the user's allowed documents in Firebase
      await firebaseHelper.updateUser(userActivity.user.uid, {
        settings: { numberOfAllowedDocuments: newAllowedDocuments },
      });
      setEditingAllowedDocuments(false);

      const newUsersActivityState = usersActivity.map((userActivity) =>
        userActivity.user.uid === userActivity.user.uid
          ? {
              ...userActivity,
              user: { ...userActivity.user, numberOfAllowedDocuments: newAllowedDocuments + '' },
            }
          : { ...userActivity },
      );

      setUsersActivity(newUsersActivityState);
      setNewAllowedDocuments(newAllowedDocuments);
    } catch (error) {
      console.error('Error updating allowed documents:', error);
    }
  };

  useEffect(() => {
    const fetchUserActivity = async () => {
      const firebaseHelper = getFirebaseBackend();
      const allUsersActivity = await firebaseHelper.getUserActivity();
      setUsersActivity(allUsersActivity);
      console.log(allUsersActivity);
    };

    fetchUserActivity();
  }, []);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleIsAdminChange = async (isAdmin: boolean) => {
    const firebaseHelper = getFirebaseBackend();
    await firebaseHelper.setAdminClaim(selectedUserActivity!.user.uid, isAdmin);

    const newUsersActivityState = usersActivity.map((userActivity) =>
      userActivity.user.uid === userActivity.user.uid
        ? {
            ...userActivity,
            user: {
              ...userActivity.user,
              numberOfAllowedDocuments: newAllowedDocuments + '',
              claims: isAdmin ? { admin: true } : {},
            },
          }
        : { ...userActivity },
    );

    setUsersActivity(newUsersActivityState);
    setSelectedUserActivity({
      ...selectedUserActivity!,
      user: {
        ...selectedUserActivity!.user,
        claims: isAdmin ? { admin: true } : null,
      },
    });
    setNewAllowedDocuments(newAllowedDocuments);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs breadcrumbItem='Admin' />
          <Card style={{ minHeight: '70vh' }}>
            <Row className='p-3'>
              <Col className='col-8'>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <div className='d-flex'>
                    <DropdownToggle caret color='primary' className='category-filter'>
                      <Row>
                        <Col className='col-10 text-start'>Select User</Col>
                        <Col className='col-2 text-end'>
                          <i className='mdi mdi-chevron-down px-1' />
                        </Col>
                      </Row>
                    </DropdownToggle>
                    <DropdownMenu>
                      {usersActivity.map((userActivity) => (
                        <DropdownItem
                          key={userActivity.user.uid}
                          onClick={() => {
                            setSelectedUserActivity(userActivity);
                            setNewAllowedDocuments(userActivity.user.numberOfAllowedDocuments);
                            setIsAdmin(!!userActivity?.user?.claims?.['admin']);
                          }}
                        >
                          {userActivity.user.email} - {userActivity.user.displayName}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </div>
                </Dropdown>
              </Col>
            </Row>
            {selectedUserActivity && (
              <div className='d-flex'>
                <table className='m-3'>
                  <tbody>
                    <tr>
                      <td style={{ width: '150px' }}>User name:</td>
                      <td className='px-3'>
                        <strong>{' ' + selectedUserActivity?.user.displayName}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td className='px-3'>
                        <strong>{selectedUserActivity?.user.email}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '100px' }}>Allowed Documents:</td>
                      <td className='px-3'>
                        <div className='d-flex gap-2'>
                          {editingAllowedDocuments ? (
                            <Input
                              className='w-25'
                              type='number'
                              value={newAllowedDocuments}
                              onChange={(e) => {
                                setNewAllowedDocuments(e.target.value);
                              }}
                            />
                          ) : (
                            <strong>{' ' + newAllowedDocuments}</strong>
                          )}
                          {editingAllowedDocuments ? (
                            <Button
                              type='button'
                              className={`btn ${'edit-provision-button-active'}`}
                              onClick={() => handleSaveAllowedDocuments(selectedUserActivity)}
                            >
                              <i className='mdi mdi-check'></i>
                            </Button>
                          ) : (
                            <Button
                              type='button'
                              className='btn edit-provision-button'
                              onClick={() => handleEditAllowedDocuments(selectedUserActivity)}
                            >
                              <i className='mdi mdi-pencil-box-outline'></i>
                            </Button>
                          )}
                        </div>
                      </td>
                      <td># of documents processed:</td>
                      <td className='px-3'>
                        <strong>
                          {
                            selectedUserActivity?.plans.filter((plan) => plan.status !== 'failed')
                              .length
                          }
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Admin:</td>
                      <td className='px-3'>
                        <input
                          type='checkbox'
                          checked={isAdmin}
                          onChange={async (e) => {
                            setIsAdmin(e.target.checked);
                            handleIsAdminChange(e.target.checked);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {/* User Accesses */}
            {selectedUserActivity && selectedUserActivity.logins?.length > 0 && (
              <div style={{ backgroundColor: '#ececec', maxHeight: '200px', overflowY: 'auto' }}>
                {' '}
                <Table style={{ position: 'relative' }}>
                  <thead>
                    <tr>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Login Date & Time
                      </th>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Provider
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserActivity.logins.map((access, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {new Date(access.timestamp._seconds * 1000).toLocaleDateString('en') +
                              ' ' +
                              new Date(access.timestamp._seconds * 1000).toLocaleTimeString('en')}
                          </td>
                          <td>{access.metadata.additionalUserInfo.providerId}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
            <br></br>
            {/* Uploaded Files */}
            {selectedUserActivity && (
              <div style={{ backgroundColor: '#ececec', maxHeight: '350px', overflowY: 'auto' }}>
                <Table style={{ position: 'relative' }}>
                  <thead>
                    <tr>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Document
                      </th>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Status
                      </th>
                      <th style={{ position: 'sticky', top: 0, backgroundColor: '#dcdcdc' }}>
                        Updated At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserActivity.plans.map((plan, index) => {
                      return (
                        <tr key={index}>
                          <td>{plan.documentName || plan.fileName}</td>
                          <td>{getStatusDescription(plan.status) + ` (${plan.status})`}</td>
                          <td>
                            {new Date((plan.updatedAt as any)._seconds * 1000).toLocaleDateString(
                              'en',
                            ) +
                              ' ' +
                              new Date((plan.updatedAt as any)._seconds * 1000).toLocaleTimeString(
                                'en',
                              )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdminUserActivity);
