import { AppDispatch } from 'index';
import React, { useRef, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Spinner, Table } from 'reactstrap';
import { updatePlan } from 'slices/thunk';
import { Plan } from '../../helpers/firebase_helper';
import EditDocumentNameModal from './editDocumentNameModal';
import {
  canDownloadFileOrViewAnswers,
  canEditPlanName,
  canSelectPlanCheckbox,
  getStatusDescription,
  shouldShowSpinnerStatus,
} from './planHelper';

interface PlansListProps {
  plans: Plan[];
  selectedPlanIds: string[];
  handleSelect: (id: string) => void;
  handleViewAnswers: (answers: any) => void;
}

const PlanTable: React.FC<PlansListProps> = ({ plans, selectedPlanIds, handleSelect }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [editedPlan, setEditedPlan] = useState<Plan>();
  const [showEditDocumentNameModal, setShowEditDocumentNameModal] = useState(false);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const handleDownload = async (downloadUrl: string, fileName: string) => {
    try {
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      if (downloadLinkRef.current) {
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = fileName;
        downloadLinkRef.current.click();
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleCheckboxClick = (event: any) => {
    const planId = event.target.value;
    handleSelect(planId);
  };

  const handleDeleteFailed = (planId) => {
    dispatch(updatePlan({ id: planId, updatedData: { status: 'deleted' } }));
  };

  return (
    <div className='table-responsive px-3'>
      <Table className='table mb-3'>
        <thead className='table-light border'>
          <tr>
            <th></th>
            <th className='plan-name-size'>Document Name</th>
            <th>Status</th>
            <th className='text-center'></th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan: Plan) => {
            return (
              <tr key={plan.id}>
                <td>
                  <input
                    type='checkbox'
                    className='checkbox-agreement-input'
                    value={plan.id}
                    id={plan.id}
                    name='agreementcheckbox'
                    onChange={(e) => e.target.value}
                    onClick={handleCheckboxClick}
                    checked={selectedPlanIds.includes(plan.id!)}
                    disabled={!canSelectPlanCheckbox(plan.status)}
                  />
                  <label htmlFor={plan.id} className='toggle' />
                </td>
                <td>
                  <div className='checkbox-agreement-column'>
                    <Row>
                      <Col className='col-12 d-flex'>
                        {plan.documentName || plan.fileName}
                        <div className='d-flex'>
                          <a
                            href='#'
                            onClick={() => handleDownload(plan.downloadUrl!, plan.fileName)}
                            className='px-1 text-dark download-file-icon'
                            title='Download Original Document'
                          >
                            <i className='mdi mdi-download download-icon'></i>
                          </a>
                          <a ref={downloadLinkRef} href='#' download hidden>
                            {' '}
                          </a>{' '}
                          {canEditPlanName(plan.status) && (
                            <>
                              <a
                                href='#'
                                onClick={async () => {
                                  setEditedPlan(plan);
                                  setShowEditDocumentNameModal(true);
                                }}
                                className='px-1 text-dark edit-document-name-icon'
                                title='Edit Document Name'
                              >
                                <i className='mdi mdi-pencil-box-outline'></i>
                              </a>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start planTableStatusColumn'>
                    <span className={`aa-list-status-${plan.status}`}>
                      {getStatusDescription(plan.status)}
                      {shouldShowSpinnerStatus(plan.status) && (
                        <Spinner className='ms-2 small-spinner' />
                      )}
                    </span>
                  </div>
                </td>
                {canDownloadFileOrViewAnswers(plan.status) && (
                  <td className='text-end plan-actions-size'>
                    <button
                      className='link-button py-1'
                      onClick={() => navigate(`/plan/${plan.id}`)}
                    >
                      View Provisions
                    </button>
                  </td>
                )}
                {!canDownloadFileOrViewAnswers(plan.status) && plan.status !== 'failed' && (
                  <td className='text-end'></td>
                )}
                {plan.status === 'failed' && (
                  <td className='text-danger'>
                    <div className='text-end'>
                      {plan.errorMessage}{' '}
                      <button
                        className='link-button-error px-4 text-end'
                        onClick={() => {
                          handleDeleteFailed(plan.id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {editedPlan && (
        <EditDocumentNameModal
          isOpen={showEditDocumentNameModal}
          toggle={() => setShowEditDocumentNameModal(false)}
          plan={editedPlan}
        />
      )}
    </div>
  );
};

export default PlanTable;
