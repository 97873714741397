import * as Excel from 'exceljs';
import { Plan } from './firebase_helper';
import { getPlanName } from './plan_helper';

// //is user is logged in
export const downloadExcelFileWithProvisionsForPlan = async (plan: Plan) => {
  const workbook = new Excel.Workbook();
  workbook.creator = 'PlanPort - PlanDataAI';
  workbook.lastModifiedBy = 'PlanPort - PlanDataAI';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  const sheet = workbook.addWorksheet(`${getPlanName(plan)}`);

  // Group answers by category
  const groupedAnswers = plan.answers!.reduce((acc, answer) => {
    const category = answer.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({
      Provisions: answer.provisionLabel,
      Answer: answer.modelAnswer,
    });
    return acc;
  }, {} as { [key: string]: any[] });

  const categoryRows: number[] = [];
  const provisionRows: number[] = [];

  // Add category headers and answers to the data array with the new format
  for (const category in groupedAnswers) {
    sheet.addRow([category]);
    categoryRows.push(sheet.rowCount);

    groupedAnswers[category].forEach((answer) => {
      sheet.addRow([answer.Provisions, answer.Answer]);
      provisionRows.push(sheet.rowCount);
    });
  }

  const getLineCount = (text: string, maxCharsPerLine: number = 60): number => {
    if (!text) {
      return 1;
    }

    const lines = text.split('\n');
    let lineCount = lines.length;

    for (const line of lines) {
      if (line.length > maxCharsPerLine) {
        lineCount += Math.floor(line.length / maxCharsPerLine);
      }
    }

    return lineCount;
  };

  provisionRows.forEach((rowNumber) => {
    const provisionRow = sheet.getRow(rowNumber);
    provisionRow.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
    provisionRow.height = getLineCount(provisionRow.getCell(2).value!.toString(), 102) * 18;

    /*provisionRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '8dd3f6' },
    };
    provisionRow.getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '8dd3f6' },
    };*/
    provisionRow.getCell(1).border = { bottom: { style: 'hair' } };
    provisionRow.getCell(2).border = { bottom: { style: 'hair' } };
  });

  categoryRows.forEach((rowNumber) => {
    const categoryRow = sheet.getRow(rowNumber);
    categoryRow.font = { bold: true };
    categoryRow.height = 30;
    categoryRow.font.color = { argb: 'ffffffff' };

    categoryRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0b628d' },
    };
    categoryRow.getCell(2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0b628d' },
    };
    categoryRow.getCell(1).border = { bottom: { style: 'thin' } };
    categoryRow.getCell(2).border = { bottom: { style: 'thin' } };

    sheet.mergeCells(rowNumber, 1, rowNumber, 2);
  });

  sheet.columns[0].width = 50;
  sheet.columns[1].width = 90;

  // Generate the Excel file as a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  // Create a temporary URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a hidden link and trigger a click to download the file
  const link = document.createElement('a');
  link.href = url;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
  const day = ('0' + currentDate.getDate()).slice(-2);
  link.download = `${getPlanName(plan)}_export_${year}${month}${day}.xlsx`;
  link.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
};
