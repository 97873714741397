import { Plan } from '@helpers/firebase_helper';
import { ProvisionAnswer } from '@pdai/shared';
import CopyToClipboardButton from 'Components/Common/CopyToClipboard';
import { AppDispatch } from 'index';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { updatePlanProvision } from 'slices/thunk';
import { formatProvisionAnswerAsHtml } from '../../helpers/plan_helper';

interface EditProvisionModalProps {
  plan: Plan;
  provision: any;
  isOpen: boolean;
  toggle: () => void;
}

const EditProvisionModal: React.FC<EditProvisionModalProps> = ({
  plan,
  provision,
  isOpen,
  toggle,
}) => {
  const [editedProvision, setEditedProvision] = useState<ProvisionAnswer>({ ...provision });
  const dispatch: AppDispatch = useDispatch();

  const handleUpdateAnswers = () => {
    dispatch(
      updatePlanProvision({ provisions: [{ ...provision, ...editedProvision }], planId: plan.id! }),
    );
    toggle();
  };

  return (
    <>
      {provision && (
        <Modal size='lg' isOpen={isOpen} toggle={toggle} className='modal-answers'>
          <ModalHeader toggle={toggle}>
            <strong>{provision.provisionLabel}</strong>
          </ModalHeader>
          <ModalBody>
            <div className='px-4 py-2'>
              <strong>PlanPort Analysis</strong>
            </div>
            <div className='px-4 d-flex'>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatProvisionAnswerAsHtml(provision.modelAnswer),
                }}
              />
              <CopyToClipboardButton text={provision.modelAnswer} />
            </div>{' '}
            <br></br>
            <div className='px-4 py-2'>
              <strong>Reviewed Version</strong>
            </div>
            <div className='px-4 w-auto'>
              <textarea
                className='w-100'
                placeholder='Enter edited version'
                defaultValue={provision.userAnswer}
                onChange={(e) => {
                  editedProvision.userAnswer = e.target.value;
                }}
                rows={4}
              />
            </div>
          </ModalBody>
          <ModalFooter className='px-4'>
            <Button className='px-4' color='secondary' onClick={handleUpdateAnswers}>
              Save
            </Button>
            <Button className='px-3' color='secondary' onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default EditProvisionModal;
