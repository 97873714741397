import { useRef, useState } from 'react';
import { Button, Toast, ToastBody } from 'reactstrap';

// New component for the copy to clipboard functionality
const CopyToClipboardButton: React.FC<{ text: string }> = ({ text }) => {
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null); // Ref for the button

  const handleCopyToClipboard = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setShowCopiedToast(true);
      setTimeout(() => {
        setShowCopiedToast(false);
      }, 1000);
      // Get button position
      const buttonRect = buttonRef.current?.getBoundingClientRect();
      if (buttonRect) {
        setToastPosition({
          top: buttonRect.top - 10,
          left: buttonRect.left + buttonRect.width,
        });
      }

      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <>
      <Button
        type='button'
        innerRef={buttonRef}
        className='btn copy-clipboard-button mx-1'
        onClick={handleCopyToClipboard}
      >
        <i className='mdi mdi-content-copy copy-icon' title='Copy to Clipboard'></i>
      </Button>

      {/* Toast for "Copied" message */}
      <div className='toast-container' style={{ position: 'fixed', ...toastPosition }}>
        <Toast isOpen={showCopiedToast} className='clipboard-copied-toast'>
          <ToastBody className='p-0'>Text copied</ToastBody>
        </Toast>
      </div>
    </>
  );
};

export default CopyToClipboardButton;
