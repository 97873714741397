import { ProvisionAnswer } from '@pdai/shared';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from 'firebase/auth';

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

export interface Plan {
  id?: string;
  userId: string;
  documentName?: string;
  fileName: string;
  fileUri?: string;
  downloadUrl?: string;
  answers?: ProvisionAnswer[];
  prompt?: string;
  status: string;
  errorMessage?: string;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}

class FirebaseHelper {
  readonly storage;
  readonly auth;
  readonly db;
  readonly functions;
  readonly planFilesCollection;
  readonly firebaseConfig;

  private readonly SESSION_EXPIRATION_TIME = 2 * 60 * 60 * 1000; // 3 hours

  constructor(firebaseConfig: any) {
    if (firebaseConfig) {
      // Initialize Firebase
      this.firebaseConfig = firebaseConfig;
      const app = initializeApp(firebaseConfig);
      this.auth = getAuth(app);

      onAuthStateChanged(this.auth, async (user) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));
          // Set initial expiration time and start timer
          this.resetSessionExpirationTimer();
          const token = await user.getIdTokenResult();
          localStorage.setItem('profileUser', token.claims?.admin ? 'admin' : 'user');
        } else {
          console.log('user logged out.');
          localStorage.removeItem('authUser');
          localStorage.removeItem('expirationTime');
        }
      });

      this.storage = getStorage(app);
      this.functions = getFunctions(app);

      // Initialize Cloud Firestore and get a reference to the service
      this.db = getFirestore(app);
      this.planFilesCollection = collection(this.db, 'planFiles');
    }
  }

  isAdminUser = () => {
    return localStorage.getItem('profileUser') == 'admin';
  };

  resetSessionExpirationTimer = (checkExpiration: boolean = false) => {
    if (checkExpiration) {
      const expirationTime = parseInt(localStorage.getItem('expirationTime') || '0');

      if (expirationTime > 0 && Date.now() > expirationTime) {
        console.log('logging out');
        this.logout().catch((error) => {
          console.error('Error signing out:', error);
        });
        window.location.href = window.location.protocol + '//' + window.location.host + '/login';
        return;
      }
    }

    const newExpirationTime = Date.now() + this.SESSION_EXPIRATION_TIME;
    localStorage.setItem('expirationTime', newExpirationTime.toString());
  };

  registerUser = (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  // Basic document analysis
  async editProfileAPI(userName: string): Promise<any> {
    try {
      const currentUser = this.auth.currentUser;
      if (currentUser) {
        await updateProfile(currentUser, { displayName: userName });
        return userName;
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error editing profile username:', error);
      throw error;
    }
  }

  loginUser = async (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  forgetPassword = (email: any) => {
    return new Promise((resolve, reject) => {
      console.log(
        'forgetPassword',
        window.location.protocol + '//' + window.location.host + '/login',
      );
      sendPasswordResetEmail(this.auth, email, {
        url: window.location.protocol + '//' + window.location.host + '/login',
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          console.log('error:', error);
          reject(this._handleError(error));
        });
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      signOut(this.auth)
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(this._handleError(error));
        });
    });
  };

  socialLoginUser = async (type: any) => {
    let provider: any;
    if (type === 'google') {
      provider = new GoogleAuthProvider();
    } else if (type === 'facebook') {
      provider = new FacebookAuthProvider();
    }
    try {
      const result = await signInWithPopup(this.auth, provider);
      const user = result.user;
      return user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  // Upload File to Firebase
  async uploadFileToStorage(file: File) {
    try {
      const newFile: Plan = {
        userId: this.auth.currentUser.uid,
        fileName: file.name,
        status: 'uploading',
        createdAt: serverTimestamp() as any,
        updatedAt: serverTimestamp() as any,
      };

      const docRef = await addDoc(collection(this.db, 'adoptionAgreements'), newFile);

      const metadata = {
        contentType: 'application/pdf',
        customMetadata: {
          documentId: docRef.id,
          fileType: 'planFile',
        },
      };

      // planFiles/USERID/DOCID/PDF_FILE.
      const filePath = `planFiles/${this.auth.currentUser.uid}/${docRef.id}/${file.name}`;
      console.log('filePath', filePath);
      const storageRef = ref(this.storage, filePath);
      await uploadBytes(storageRef, file, metadata);
    } catch (error) {
      console.log(error);
    }
  }

  async updatePlan(id: string, updatedData: Partial<Plan>): Promise<Partial<Plan>> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const docRef = doc(this.db, 'adoptionAgreements', id);
        await updateDoc(docRef, { ...updatedData });
        return { id, ...updatedData };
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating plans:', error);
      throw error;
    }
  }

  async updateUser(id: string, updatedData: any): Promise<any> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const docRef = doc(this.db, 'users', id);
        await updateDoc(docRef, { ...updatedData });
        return { id, ...updatedData };
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating users:', error);
      throw error;
    }
  }

  async updatePlanWithProvisionAnswer(
    newProvisionAnswers: ProvisionAnswer[],
    planId: string,
  ): Promise<Partial<Plan>> {
    try {
      console.log('newProvisionAnswers', newProvisionAnswers);
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        // Assuming there's only one matching document
        const docRef = doc(this.db, 'adoptionAgreements', planId);
        const planToBeUpdated = await getDoc(docRef);

        const updatedData = {
          answers: planToBeUpdated.data()!.answers.map((oldAnswer: any) => {
            const foundProvision = newProvisionAnswers.find(
              (newProvision) => oldAnswer.provisionName === newProvision.provisionName,
            );
            return foundProvision || oldAnswer;
          }),
          updatedAt: serverTimestamp() as any,
        };

        await updateDoc(docRef, updatedData);

        return updatedData;
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating plan with provision answer:', error);
      throw error;
    }
  }

  async getUserActivity(): Promise<
    {
      user: {
        uid: string;
        email: string;
        displayName: string;
        provider: any;
        metadata: any;
        numberOfAllowedDocuments: string;
        claims: {
          [key: string]: any;
        };
      };
      logins: {
        userId: string;
        email: string;
        timestamp: any;
        metadata: any;
      }[];
      plans: Plan[];
    }[]
  > {
    try {
      const getUserActivityFunction = httpsCallable<
        any,
        {
          user: {
            uid: string;
            email: string;
            displayName: string;
            provider: any;
            metadata: any;
            numberOfAllowedDocuments: string;
            claims: {
              [key: string]: any;
            };
          };
          logins: {
            userId: string;
            email: string;
            timestamp: any;
            metadata: any;
          }[];
          plans: Plan[];
        }[]
      >(this.functions, 'getUserActivity');

      const result = await getUserActivityFunction();
      return result.data;
    } catch (error) {
      console.error('Error processing file:', error);
      throw error;
    }
  }

  async setAdminClaim(uid: string, isAdmin: boolean) {
    try {
      const setCustomClaimsFunction = httpsCallable(this.functions, 'setAdminClaim');
      await setCustomClaimsFunction({ userId: uid, isAdmin: isAdmin });
      console.log(`Admin claim set for user: ${uid}`);
    } catch (error) {
      console.error('Error setting custom claim:', error);
      throw error;
    }
  }

  getCurrentUser() {
    const user = localStorage.getItem('authUser');

    if (!user) {
      throw new Error('User not authenticated');
    }
    return JSON.parse(user);
  }

  _handleError(error: any) {
    console.log(error.code);

    if ((error.message as string).indexOf('Unauthorized email') > -1) {
      return `Oops! It looks like you don't have access to this application. If you believe this is an error, please contact support.`;
    }

    if (error.code === 'auth/popup-closed-by-user') {
      return;
    }
    const errorMessage = error.message;
    return errorMessage;
  }
}

export { FirebaseHelper };
