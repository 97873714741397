import React, { useState } from 'react';
import GoogleButton from 'react-google-button';
// Redux
import withRouter from 'Components/Common/withRouter';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createSelector } from 'reselect';
import { loginuser, resetLoginMsgFlag, socialLogin } from 'slices/auth/login/thunk';
import * as Yup from 'yup';

import logo from '../../assets/images/pdai/PlanPortlogo2Color.jpg';

const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch: any = useDispatch();

  //meta title
  document.title = 'Login | PlanPort';

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (login) => ({
      error: login.error,
    }),
  );

  const { error } = useSelector(selectProperties);

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values: any) => {
      dispatch(resetLoginMsgFlag());
      dispatch(loginuser(values, props.router.navigate));
    },
  });

  const signIn = (type: any) => {
    dispatch(resetLoginMsgFlag());
    setIsLoading(true);
    dispatch(socialLogin(type, props.router.navigate)).finally(() => {
      setIsLoading(false);
    });
  };

  const socialResponse = (type: any) => {
    signIn(type);
  };

  const handleContactUsClick = () => {
    window.location.href = 'mailto:support@plandataai.com';
  };

  return (
    <React.Fragment>
      <div className='account-pages my-3 pt-sm-3'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <div className=''>
                  <Row>
                    <Col className='col-12'>
                      <div className='text-secondary p-3 pl-4'>
                        <span className='logo-container'>
                          <img src={logo} alt='' className='logo-login' />
                        </span>
                        <p>Sign in to continue to PlanPort.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className='pt-1'>
                  <div className='auth-logo'></div>
                  <div className='p-2'>
                    <Form
                      className='form-horizontal'
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className='mb-3'>
                        {error ? (
                          <Alert
                            color='danger'
                            isOpen={!!error}
                            toggle={() => {
                              dispatch(resetLoginMsgFlag());
                            }}
                          >
                            {error}
                          </Alert>
                        ) : null}

                        <Label className='form-label'>Email</Label>
                        <Input
                          name='email'
                          className='form-control'
                          placeholder='Enter email'
                          type='text'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type='invalid'>{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className='mb-3'>
                        <Label className='form-label'>Password</Label>
                        <div className='input-group auth-pass-inputgroup'>
                          <Input
                            name='password'
                            value={validation.values.password || ''}
                            type={show ? 'text' : 'password'}
                            placeholder='Enter Password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className='btn btn-light '
                            type='button'
                            id='password-addon'
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type='invalid'>{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className='form-check'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          id='customControlInline'
                        />
                        <label className='form-check-label' htmlFor='customControlInline'>
                          Remember me
                        </label>
                      </div>

                      <div className='mt-3 d-grid'>
                        <button className='btn btn-primary btn-block ' type='submit'>
                          Log In
                        </button>
                      </div>

                      <div className='mt-4 text-center'>
                        <Link to='/forgot-password' className='text-muted'>
                          <i className='mdi mdi-lock me-1' /> Forgot your password?
                        </Link>
                      </div>

                      <div className='mt-4 text-center'>
                        <ul className='list-inline'>
                          <li className='list-inline-item mt-4'>
                            <GoogleButton
                              style={{ transform: 'scale(0.7)' }}
                              disabled={isLoading}
                              onClick={(e: any) => {
                                e.preventDefault();
                                socialResponse('google');
                              }}
                            />
                            {isLoading && (
                              <div className='py-2'>
                                <div
                                  className='spinner-border spinner-border-sm'
                                  role='status'
                                ></div>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-3 text-center'>
                <p>
                  Don't have an account ?{' '}
                  <span
                    onClick={handleContactUsClick}
                    className='fw-medium text-primary cursor-pointer' // Add cursor-pointer for visual feedback
                  >
                    Contact us
                  </span>{' '}
                </p>
                <br></br>
                <p>© {new Date().getFullYear()} PlanDataAI LLC. All Rights Reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
