import { Plan } from '@helpers/firebase_helper';
import Spinners from 'Components/Common/Spinner';
import { Timestamp } from 'firebase/firestore';
import { AppDispatch } from 'index';
import { getStatusDescription } from 'pages/PlanList/planHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Row } from 'reactstrap';
import { fetchPlanById, updatePlan } from 'slices/thunk';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import { downloadExcelFileWithProvisionsForPlan } from '../../helpers/excel_provisions';
import ProvisionsTable from './provisionsTable';

const PlanDetails = () => {
  document.title = 'PlanPort - Plan Details';
  document.body.classList.add('vertical-collpsed');
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const { plan, loading } = useSelector((state: any) => ({
    plan: state.Plan.selectedPlanDetails as Plan,
    loading: state.Plan.loading as boolean,
  }));

  const handleArchivePlan = () => {
    dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'archived' } }));
  };

  const handleInUserReviewPlan = () => {
    dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'inUserReview' } }));
  };

  const handleReviewPlan = () => {
    setErrorMessage('');
    if (plan.answers?.some((answer) => !answer.reviewed)) {
      setErrorMessage('Review all provisions before marking the plan as Reviewed.');
    } else {
      dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'reviewed' } }));
    }
  };

  useEffect(() => {
    dispatch(fetchPlanById(id!));
  }, [dispatch, id]);

  const handleDownloadExcel = () => {
    downloadExcelFileWithProvisionsForPlan(plan);
  };

  return (
    <div className='page-content'>
      <React.Fragment>
        <Container fluid>
          <Breadcrumbs title='My Plans' breadcrumbItem='Plan Review' />

          <Row>
            <Col xs={12}>
              <Card>
                {loading && <Spinners />}
                {/* TOP Section */}
                {!loading && plan && (
                  <Row className=' px-3 d-flex align-items-center'>
                    <Col className='col-4'>
                      <div className='planDetail-top-section'>
                        Document: <strong>{plan.documentName || plan.fileName}</strong>
                        <br></br>
                        Uploaded at:{' '}
                        <strong>
                          {(plan.createdAt as Timestamp)?.toDate().toLocaleDateString('en', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          })}
                        </strong>
                        <br></br>
                        Status: <strong>{getStatusDescription(plan.status)}</strong>
                      </div>
                    </Col>
                    <Col className='col-8'>
                      <div className='d-flex justify-content-end gap-3'>
                        {plan.status !== 'reviewed' &&
                          plan.status !== 'processingLocationOCR' &&
                          plan.status !== 'processingLocationAI' && (
                            <Button
                              type='button'
                              color='secondary'
                              className='btn px-4'
                              onClick={handleReviewPlan}
                            >
                              Mark Plan as Reviewed
                            </Button>
                          )}
                        {plan.status !== 'inUserReview' &&
                          plan.status !== 'processingLocationOCR' &&
                          plan.status !== 'processingLocationAI' && (
                            <Button
                              type='button'
                              color='secondary'
                              className='btn px-4'
                              onClick={handleInUserReviewPlan}
                            >
                              Mark Plan as Pending Review
                            </Button>
                          )}
                        {plan.status !== 'archived' &&
                          plan.status !== 'processingLocationOCR' &&
                          plan.status !== 'processingLocationAI' && (
                            <Button
                              type='button'
                              color='secondary'
                              className='btn px-4'
                              onClick={handleArchivePlan}
                            >
                              Archive Plan
                            </Button>
                          )}
                        <Button
                          type='button'
                          color='secondary'
                          className='btn '
                          onClick={handleDownloadExcel}
                        >
                          Download to Excel
                        </Button>

                        <Button
                          type='button'
                          color='gray-400'
                          className='btn px-5 my-0'
                          onClick={() => navigate(`/plans`)}
                        >
                          Back to My Plans
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
                {errorMessage && (
                  <div className='align-self-center text-center px-3'>
                    <Alert
                      color='danger'
                      role='alert'
                      toggle={() => {
                        setErrorMessage('');
                      }}
                    >
                      {errorMessage}
                    </Alert>
                  </div>
                )}
                {!loading && plan && <ProvisionsTable plan={plan} />}
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
};

export default PlanDetails;
