import { ProvisionAnswer } from '@pdai/shared';
import { Plan } from './firebase_helper';

export const getPlanName = (plan: Plan): string | undefined => {
  if (plan && plan.answers && plan.answers.length > 0) {
    const planNameProvision = plan.answers.find(
      (provision: ProvisionAnswer) => provision.provisionName == 'planName',
    );
    return planNameProvision?.userAnswer || planNameProvision?.modelAnswer;
  }
  return undefined;
};

export const formatProvisionAnswerAsHtml = (text: string) => {
  return text
    .replace(/\n/g, '<br>')
    .replace(/\r/g, '') // Remove carriage returns
    .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'); // Replace tabs with spaces
};
