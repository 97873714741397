import { Plan } from '@helpers/firebase_helper';
import { ProvisionAnswer } from '@pdai/shared';
import CopyToClipboardButton from 'Components/Common/CopyToClipboard';
import { AppDispatch } from 'index';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { updatePlan } from 'slices/thunk';

interface EditDocumentName {
  plan: Plan;
  isOpen: boolean;
  toggle: () => void;
}

const EditDocumentNameModal: React.FC<EditDocumentName> = ({ plan, isOpen, toggle }) => {
  const [editedDocumentName, setEditedDocumentName] = useState<string | undefined>('');
  const dispatch: AppDispatch = useDispatch();

  const handleUpdateAnswers = () => {
    dispatch(updatePlan({ id: plan.id!, updatedData: { documentName: editedDocumentName } }));
    toggle();
  };

  useEffect(() => {
    setEditedDocumentName('');
  }, [isOpen]);

  const getPlanNameFromProvisions = (): string | undefined => {
    if (plan && plan.answers && plan.answers.length > 0) {
      const planNameProvision = plan.answers.find(
        (provision: ProvisionAnswer) => provision.provisionName == 'planName',
      );
      return planNameProvision?.userAnswer || planNameProvision?.modelAnswer;
    }
    return undefined;
  };

  return (
    <>
      {plan && (
        <Modal size='lg' isOpen={isOpen} toggle={toggle} className='modal-answers'>
          <ModalHeader toggle={toggle}>
            <strong>Editing Document Name</strong>
          </ModalHeader>
          <ModalBody>
            <div className='px-4 py-2'>
              Add a friendly name for your document to help identification and organization.
            </div>
            <div className='px-4 py-2'>
              <strong>Document Name</strong>
            </div>
            <div className='px-4 w-auto'>
              <input
                type='text'
                className='w-100'
                placeholder='Enter document name'
                defaultValue={plan.documentName}
                onChange={(e) => {
                  console.log('newValue: ' + e.target.value);
                  setEditedDocumentName(e.target.value);
                }}
              />
            </div>
            <div className='p-3'></div>
            <Row>
              <Col>
                <div className='px-4 py-2'>
                  <strong>File name</strong>
                </div>
                <div className='px-4 d-flex'>
                  <span>{plan.fileName}</span> <CopyToClipboardButton text={plan.fileName} />
                </div>
              </Col>
              <Col>
                {getPlanNameFromProvisions() !== undefined && (
                  <>
                    <div className='px-4 py-2'>
                      <strong>Plan Name (from PlanPort analysis)</strong>
                    </div>
                    <div className='px-4 d-flex'>
                      <span>{getPlanNameFromProvisions()}</span>
                      <CopyToClipboardButton text={getPlanNameFromProvisions()!} />
                    </div>
                    <br></br>
                  </>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className='px-4'>
            <Button className='px-4' color='secondary' onClick={handleUpdateAnswers}>
              Save
            </Button>
            <Button className='px-3' color='secondary' onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default EditDocumentNameModal;
