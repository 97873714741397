import React from 'react';
import { Navigate } from 'react-router-dom';
import { getFirebaseBackend } from '../firebase';

const AdminProtected = (props) => {
  console.log('AdminProtected');
  if (!getFirebaseBackend().isAdminUser()) {
    getFirebaseBackend().logout();
    return <Navigate to={{ pathname: '/login' }} />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AdminProtected;
