import React, { useEffect, useRef, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import '../../App.css';
import '../../assets/scss/theme.scss';

import { CategoryList, ProvisionAnswer } from '@pdai/shared';
import PDFJSViewer from 'Components/Common/PDFJSViewer/PDFJSViewer';
import { AppDispatch } from 'index';
import { areLocationsAvailable } from 'pages/PlanList/planHelper';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { updatePlanProvision } from 'slices/thunk';
import { Plan } from '../../helpers/firebase_helper';
import { formatProvisionAnswerAsHtml } from '../../helpers/plan_helper';
import EditProvisionModal from './editProvisionModal';
import { drawNewAnnotation } from './PdfAnnotationsHelper';

interface ProvisionsTableProps {
  plan: Plan;
}

const ProvisionsTable: React.FC<ProvisionsTableProps> = ({ plan }) => {
  const [showEditProvisionModal, setShowEditProvisionModal] = useState(false);
  const [selectedProvision, setSelectedProvision] = useState<ProvisionAnswer | null>(null);
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy>();
  const [canvasBeforeAnnotation, setCanvasBeforeAnnotation] = useState<{
    pageIndex: number;
    imageData: ImageData;
    canvasContext: CanvasRenderingContext2D;
  } | null>(null);
  const [displayPDF, setDisplayPDF] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [categoriesReviewed, setCategoriesReviewed] = useState<{ [key: string]: boolean }>({});

  const componentRef = useRef<HTMLDivElement>(null);

  const dispatch: AppDispatch = useDispatch();

  const getPdfDoc = (): PDFDocumentProxy => {
    return pdfDoc!;
  };

  const showWaitCursor = (seconds: number, event) => {
    event.target.style.cursor = 'wait';
    document.body.style.cursor = 'wait';

    setTimeout(() => {
      document.body.style.cursor = 'default';
      event.target.style.cursor = 'pointer';
    }, seconds * 1000);
  };

  const handleReviewAnswers = (provision: ProvisionAnswer, event) => {
    showWaitCursor(2, event);
    dispatch(
      updatePlanProvision({
        provisions: [{ ...provision!, reviewed: !provision?.reviewed }],
        planId: plan.id!,
      }),
    );
    refreshCategoriesReviewed();
  };

  const handleReviewAllAnswersFromCategory = (category: string, reviewed: boolean, event) => {
    showWaitCursor(2, event);

    const categoryProvisions = plan.answers?.filter((answer) => answer.category == category);

    dispatch(
      updatePlanProvision({
        provisions: categoryProvisions!.map((provision) => {
          return {
            ...provision,
            reviewed: reviewed,
          };
        }),
        planId: plan.id!,
      }),
    );
  };

  const refreshCategoriesReviewed = () => {
    const categoryStatus: { [key: string]: boolean } = {};

    // Collect all unique categories and their review status
    plan.answers?.forEach((answer) => {
      if (!(answer.category in categoryStatus)) {
        categoryStatus[answer.category] = true;
      }

      if (!answer.reviewed) {
        categoryStatus[answer.category] = false;
      }
    });
    setCategoriesReviewed(categoryStatus);
  };

  const handleClickLocation = async (provision: ProvisionAnswer, index) => {
    if (canvasBeforeAnnotation) {
      if (canvasBeforeAnnotation.imageData) {
        canvasBeforeAnnotation.canvasContext.putImageData(canvasBeforeAnnotation.imageData, 0, 0);
      }
    }

    // get new annotation page canvas.
    const canvas = document.getElementById(
      `canvas-page-${provision.locations[index].pageNumber + 1}`,
    ) as HTMLCanvasElement;
    const canvasContext = canvas!.getContext('2d')!;

    // save canvas state before notification.
    const lastAnnotationCanvasState = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
    setCanvasBeforeAnnotation({
      pageIndex: provision.locations[index].pageNumber + 1,
      imageData: lastAnnotationCanvasState,
      canvasContext: canvasContext,
    });

    const { y } = drawNewAnnotation(provision, index, canvas, canvasContext);

    // scroll to annotation
    const containerDiv = document.getElementById(`pdfViewerForAnnotation`) as HTMLDivElement;

    // 1. Get the canvas and target coordinates
    const rect = canvas.getBoundingClientRect();
    const targetY = rect.top + y;

    // 2. Calculate the center position within the parent div
    const centerY = containerDiv.offsetHeight / 2;

    // 3. Calculate the scroll offset
    const scrollY = targetY - centerY;

    // 4. Scroll the parent div
    containerDiv.scrollBy({
      left: 0,
      top: scrollY - 100,
      behavior: 'smooth', // Optional for smooth scrolling
    });
  };

  const isFullScreenDocument = () => {
    const element =
      document.fullscreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).msFullscreenElement;
    console.log(element);
    return !!element;
  };

  const toggleFullScreen = () => {
    const element = componentRef.current;
    if (!element) {
      return;
    }

    if (!isFullScreenDocument()) {
      setIsFullScreen(true);
      document.body.removeAttribute('data-layout-mode');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if ((element as any).webkitRequestFullscreen) {
        /* Safari */
        (element as any).webkitRequestFullscreen();
      } else if ((element as any).msRequestFullscreen) {
        /* IE11 */
        (element as any).msRequestFullscreen();
      }
    } else {
      setIsFullScreen(false);
      console.log('vai fechar full-screen');
      if ((document as any).exitFullscreen) {
        (document as any).exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        /* Safari */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        /* Firefox */
        (document as any).mozCancelFullScreen();
      } else if ((document as any).msExitFullscreen) {
        /* IE11 */
        (document as any).msExitFullscreen();
      }
    }
  };

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const planHasLocations = (): boolean => {
    return (
      areLocationsAvailable(plan.status) && !!plan.answers && plan.answers[0].locations?.length > 0
    );
  };

  const handleCategoryClick = (category: string) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const convertToCamelCase = (sectionName: string): string => {
    return (
      sectionName ||
      ''
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    );
  };

  useEffect(() => {
    refreshCategoriesReviewed();
  }, [plan]);

  return (
    <div ref={componentRef} className='provisionsTableFullScreen'>
      <Row dir='row' className='provisionTableOptions'>
        <Col className='col-8'>
          <div className='displayCategoriesFilter'>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <div className='d-flex'>
                <DropdownToggle caret color='primary' className='category-filter'>
                  <Row>
                    <Col className='col-10 text-start'>Select Categories</Col>
                    <Col className='col-2 text-end'>
                      <i className='mdi mdi-chevron-down px-1' />
                    </Col>
                  </Row>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setSelectedCategories([]);
                    }}
                  >
                    Clear Filter
                  </DropdownItem>
                  <div className='dropdown-divider'></div>
                  {CategoryList.map((category) => (
                    <DropdownItem
                      key={category}
                      onClick={() => handleCategoryClick(category)}
                      toggle={false}
                      disabled={
                        selectedCategories.length >= 3 && !selectedCategories.includes(category)
                      }
                    >
                      <Input type='checkbox' checked={selectedCategories.includes(category)} />{' '}
                      {category}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
                <div className='mt-2 px-3'>{selectedCategories.join(', ')}</div>
              </div>
            </Dropdown>
          </div>
        </Col>
        <Col className='col-4'>
          <div className='displayPDF'>
            <span className='showFullScreenLabel'>
              {isFullScreen ? 'Exit full-screen' : 'Show full-screen'}
            </span>
            <i className='bx bx-fullscreen setFullScreen' onClick={toggleFullScreen} />
            <span className='displayPDFLabel'>Display PDF</span>
            <div className='form-check form-switch form-switch-lg'>
              <Input
                type='switch'
                checked={displayPDF}
                onClick={() => {
                  setDisplayPDF(!displayPDF);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>{' '}
      <Row>
        <Col className={`${displayPDF ? 'col-5 answers-container' : 'col-12'}`}>
          <div className={`${displayPDF ? '' : 'm-3 answers-container-full'}`}>
            <Table className={`${displayPDF ? 'm-3 provision-table' : 'provision-table-full'}`}>
              {CategoryList.map((category) => {
                return selectedCategories.length === 0 || selectedCategories.includes(category) ? (
                  <tbody key={category} className='my-5'>
                    <tr key='firstHeader'>
                      <th
                        className='px-4 provision-category-header-first-row'
                        colSpan={displayPDF ? 3 : 2}
                      >
                        {category}
                      </th>
                      <th
                        className='px-3 provision-category-header-first-row'
                        colSpan={displayPDF ? 1 : 2}
                      >
                        <Row>
                          {plan.status !== 'processingLocationOCR' &&
                            plan.status !== 'processingLocationAI' &&
                            displayPDF && (
                              <Col className='col-6 px-3 py-3'>
                                <a
                                  title={
                                    categoriesReviewed[category]
                                      ? `Mark all provisions from "${category}" category as pending review`
                                      : `Mark all provisions from "${category}" category as reviewed`
                                  }
                                >
                                  <Button
                                    type='button'
                                    className={`btn ${
                                      categoriesReviewed[category]
                                        ? 'edit-provision-button-active'
                                        : 'edit-provision-button'
                                    }`}
                                    onClick={(event) => {
                                      handleReviewAllAnswersFromCategory(
                                        category,
                                        !categoriesReviewed[category],
                                        event,
                                      );
                                    }}
                                  >
                                    <i className='mdi mdi-check'></i>
                                  </Button>
                                </a>
                              </Col>
                            )}{' '}
                          {plan.status !== 'processingLocationOCR' &&
                            plan.status !== 'processingLocationAI' &&
                            !displayPDF && (
                              <Col className='col-12 py-3 text-end'>
                                <Button
                                  type='button'
                                  color='gray-400'
                                  className='btn my-0 w-75 mx-2'
                                  onClick={(event) => {
                                    handleReviewAllAnswersFromCategory(
                                      category,
                                      !categoriesReviewed[category],
                                      event,
                                    );
                                  }}
                                >
                                  {categoriesReviewed[category]
                                    ? `Mark provisions as Pending Review`
                                    : `Mark provisions as Reviewed`}
                                </Button>
                              </Col>
                            )}
                        </Row>
                      </th>
                    </tr>
                    <tr key='secondHeader'>
                      <th className='px-4 provision-label-header'>Provision</th>
                      <th className='provision-label-header'>Approved Version</th>

                      {planHasLocations() && displayPDF && (
                        <th className='provision-label-header text-center'>Locations</th>
                      )}
                      {planHasLocations() && !displayPDF && (
                        <th className='provision-label-header provision-label-header-locations text-center'>
                          Page & Section
                        </th>
                      )}
                      {!planHasLocations() && (
                        <th className='provision-label-header text-center'></th>
                      )}
                      <th className='provision-label-header provision-label-header-actions text-center'>
                        Actions
                      </th>
                    </tr>
                    {plan.answers &&
                      plan.answers?.map((provision) => {
                        if (provision.category == category) {
                          return (
                            <tr key={provision.provisionName} className='align-middle'>
                              {/* Display Provision Label */}
                              <td className='px-4'>{provision.provisionLabel}</td>

                              {/* Display Provision Answers (user or model) */}
                              {provision.userAnswer && (
                                <td className='edited-provision'>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: formatProvisionAnswerAsHtml(provision.userAnswer),
                                    }}
                                  />
                                </td>
                              )}
                              {!provision.userAnswer && (
                                <td>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: formatProvisionAnswerAsHtml(provision.modelAnswer),
                                    }}
                                  />
                                </td>
                              )}

                              {planHasLocations() && displayPDF && (
                                <td>
                                  {provision.locations &&
                                    provision.locations.map((location, index) => {
                                      return (
                                        <Button
                                          type='button'
                                          className='btn find-in-document-button mx-4'
                                          onClick={() => {
                                            handleClickLocation(provision, index);
                                          }}
                                        >
                                          {index + 1}
                                        </Button>
                                      );
                                    })}
                                </td>
                              )}

                              {planHasLocations() && !displayPDF && (
                                <td>
                                  {provision.locations &&
                                    provision.locations.map((location) => {
                                      return (
                                        <>
                                          <strong>Page:</strong> {location.pageNumber + 1}
                                          {' - '}
                                          <strong>Section:</strong>{' '}
                                          {convertToCamelCase(location.section)}
                                          <br></br>
                                        </>
                                      );
                                    })}
                                </td>
                              )}
                              {!planHasLocations() && <td></td>}

                              <td className='px-3'>
                                <Row>
                                  <Col className='col-6 px-3'>
                                    <Button
                                      type='button'
                                      className={`btn ${
                                        provision.reviewed
                                          ? 'edit-provision-button-active'
                                          : 'edit-provision-button'
                                      }`}
                                      onClick={async (event) => {
                                        handleReviewAnswers(provision, event);
                                      }}
                                    >
                                      <i className='mdi mdi-check'></i>
                                    </Button>
                                  </Col>
                                  <Col className='col-6 '>
                                    <Button
                                      type='button'
                                      className='btn edit-provision-button'
                                      onClick={async () => {
                                        setSelectedProvision(provision);
                                        setShowEditProvisionModal(true);
                                      }}
                                    >
                                      <i className='mdi mdi-pencil-box-outline'></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                ) : (
                  <></>
                );
              })}
            </Table>
          </div>
          <EditProvisionModal
            isOpen={showEditProvisionModal}
            toggle={() => setShowEditProvisionModal(false)}
            plan={plan}
            provision={selectedProvision}
          />
        </Col>
        <Col className={`${displayPDF ? 'col-7' : 'd-none'} pdf-container`}>
          {plan.downloadUrl && (
            <div className='viewerContainer' id='pdfViewerForAnnotation'>
              <PDFJSViewer
                pdfUrl={plan.downloadUrl}
                setPdfDoc={setPdfDoc}
                getPdfDoc={getPdfDoc}
                refreshPDF={displayPDF}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProvisionsTable;
